<template>
  <Scaffold>
    <template #buttons>
      <Button @click="() => openFormModal()"
              type="primary">추가
      </Button>
    </template>

    <div class="page-content">
      <CTable
          header-key="userList"
          v-bind="tableBinding()"
          @page-change="pageChange"
          @reload="getData"
          v-model="searchParams.search_keyword"
          @on-search="onSearch"
          search-placeholder="ID, 휴대폰 번호, 이름으로 검색"
      >
        <template #action="{row}">
          <div class="r-gap-buttons">
            <Button size="small" :to="{name: 'user-editor', params: {id: row.id}}">수정</Button>
            <Button size="small" :to="{name: 'user-refund-info', params: {id: row.id}}">환불 정보</Button>
          </div>
        </template>
      </CTable>

      <CModal
          v-model="formModal.open"
          :loading="formModal.loading"
          title="새 유저 추가"
          @on-ok="onFormModalSubmit"
          ok-text="추가"
      >
        <Form :label-width="80" label-position="right">
          <FormItem label="이름">
            <Input v-model="form.name"></Input>
          </FormItem>
          <FormItem label="아이디">
            <Input v-model="form.username"></Input>
          </FormItem>
          <FormItem label="비밀번호">
            <Input v-model="form.password" type="password"></Input>
          </FormItem>
          <FormItem label="테스트">
            <Checkbox v-model="form.is_test">테스트용 유저</Checkbox>
          </FormItem>
          <FormItem label="역할">
            <Select v-model="form.role">
              <Option v-for="r in ['normal', 'staff', 'admin']"
                      :value="r" :key="`role-option-${r}`"
              >{{ r }}
              </Option>
            </Select>
          </FormItem>
        </Form>
      </CModal>
    </div>
  </Scaffold>
</template>

<script>
import {tableMixin} from "@/mixins/table";
import {formModalMixin} from "@/mixins/formModal";

export default {
  mixins: [tableMixin, formModalMixin],
  data() {
    return {
      apiUrl: 'user',
      filterParams: {},
      form: {},
    }
  },
  methods: {
    getDefaultForm() {
      return {
        username: '',
        name: '',
        is_test: false,
        role: 'normal',
        password: ''
      }
    },
  },
  mounted() {
    this.getData();
  }
}
</script>

<style lang="scss" scoped>
</style>
