export const formModalMixin = {
  computed: {
    formUpdateUrl() {
      return `${this.formCreateUrl}/${this.formModal.curId}`
    },
    formCreateUrl() {
      return this.apiUrl
    },
    isUpdating() {
      return !!this.formModal.curId
    },
  },
  data() {
    return {
      formModal: {
        open: false,
        curId: null,
        loading: false,
      },
    }
  },
  methods: {
    getDefaultForm() {
      return {}
    },
    openFormModal(id) {
      if (id) {
        this.formModal.curId = id
        this.getFormData((res) => {
          this.form = res.result
          this.formModal.open = true
        })
      } else {
        this.formModal.curId = null
        this.formModal.open = true
        this.form = this.getDefaultForm()
      }
    },
    onFormModalSubmit() {
      if (this.isUpdating) {
        this.onUpdateSubmit()
      } else {
        this.onCreateSubmit()
      }
    },
    onCreateSubmit() {
      this.formModal.loading = true
      this.$axios.post(this.formCreateUrl, this.form).then(res => {
        this.messages.success('생성이 완료되었습니다.')
      }).finally(() => {
        this.formModal.loading = false
        this.afterSubmit()
      })
    },
    onUpdateSubmit() {
      this.formModal.loading = true
      this.$axios.patch(this.formUpdateUrl, this.form).then(res => {
        this.messages.success('수정이 완료되었습니다.')
      }).finally(() => {
        this.formModal.loading = false
        this.afterSubmit()
      })
    },
    afterSubmit() {
      this.getData()
    },
    getFormData(after) {
      this.$axios.$get(this.formUpdateUrl).then(res => {
        after(res)
      })
    }
  }
}